import React from 'react'
import clsx from 'clsx'

import { FeaturedPostPreview } from '~/components/PostPreview/PostPreview'
import { Post } from '~/content/blog/types'

import styles from './HomeFeaturedPost.module.sass'

interface Props {
  featuredPost: Post
}

const HomeFeaturedPost: React.FC<Props> = ({ featuredPost }) => {
  return (
    <div className={clsx(styles.wrapper, 'site-container site-grid')}>
      <div className='col-span-full md:col-start-2 md:col-end-8 lg:col-start-4 lg:col-end-10'>
        <FeaturedPostPreview {...featuredPost} />
      </div>
    </div>
  )
}

export default HomeFeaturedPost
