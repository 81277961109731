/**
 * Takes date as an argument and returns humanized time ago value.
 */
export const getTimeAgo = (date: string) => {
  const MINUTE = 60
  const HOUR = MINUTE * 60
  const DAY = HOUR * 24
  const WEEK = DAY * 7
  const MONTH = DAY * 30
  const YEAR = DAY * 365

  if (!/^\d{4}-\d{2}-\d{2}$/gm.test(date) && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\D{2}Z$/gm.test(date)) {
    throw new Error(`Incorrect date format: '${date}'. Make sure to use the ISO 8601 format (YYYY-MM-DD).`)
  }

  const secondsAgo = Math.round((+new Date() - +new Date(date)) / 1000)

  let settings = {
    divisor: 1,
    unit: 'second',
  }

  if (secondsAgo < DAY) {
    return 'Today'
  } else if (secondsAgo < DAY * 2) {
    return 'Yesterday'
  } else if (secondsAgo < WEEK) {
    settings = { divisor: DAY, unit: 'day' }
  } else if (secondsAgo < MONTH) {
    settings = { divisor: WEEK, unit: 'week' }
  } else if (secondsAgo < YEAR) {
    settings = { divisor: MONTH, unit: 'month' }
  } else if (secondsAgo > YEAR) {
    settings = { divisor: YEAR, unit: 'year' }
  }

  const count = Math.floor(secondsAgo / settings.divisor)

  const formatedCountValue = (count: number): string | number => {
    if (count === 1) {
      return 'One'
    } else if (count === 2) {
      return 'Two'
    } else if (count === 3) {
      return 'Three'
    } else {
      return count
    }
  }

  return `${formatedCountValue(count)} ${settings.unit}${count > 1 ? 's' : ''} ago`
}
