import Image from 'next/legacy/image'
import clsx from 'clsx'

import Button from '~/components/Button/Button'
import Text from '~/components/Text/Text'
import Link from '~/components/Link/Link'
import { getTimeAgo } from '~/lib/date'
import { FCWithChildren } from '~/lib/types'

import { PostPreviewProps, PostProps } from './types'
import styles from './PostPreview.module.sass'

export const PostPreview: FCWithChildren<PostPreviewProps> = ({
  date,
  title,
  excerpt,
  authors,
  url,
  cta = 'Read article',
  className,
  trackLabel = '',
}) => {
  const trackAttrs = `cta:read-article:${url}:${trackLabel}`
  return (
    <div className={clsx(styles.post, className)}>
      <Text suppressHydrationWarning={true} variant='caption' fontSize={[12, 10, 12, 12]}>
        {getTimeAgo(date)}
      </Text>

      <Text className={styles.title} variant='subheading' fontSize={[28, 28, 28, 30]}>
        <Link href={url} data-track={trackAttrs}>
          {title}
        </Link>
      </Text>

      <Text className={styles.excerpt} variant='body' fontSize={[18, 14, 18, 18]}>
        {excerpt}
      </Text>

      <div className='flex justify-items-center'>
        <Button variant='secondary' className={styles.button} link={url} data-track={trackAttrs}>
          {cta}
        </Button>
        <div className={styles.authors}>
          {authors.map(({ name, avatar }) => (
            <div key={name} className={styles.avatar}>
              <Image src={avatar} alt={name} width={50} height={50} className={styles.authorImage} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export const FeaturedPostPreview: React.FC<PostProps> = ({ ...post }) => (
  <PostPreview cta='Read blog post' trackLabel='from-featured' {...post} />
)
